let Form = {
    $form: undefined,
    ID: undefined,
    onload: document.addEventListener('DOMContentLoaded', function() { Form.init(); }),
    init: function() {
        this.ajax.url = document.querySelector('meta[name="ajaxUrl"]').content;
        this.bind();
    },
    bind: function() {
        $(document).on('click', 'button.submit-form', function(e) {
            e.preventDefault();
            let $submitBtn = $(this).prop('disabled', true);
            Form.$form = $submitBtn.closest('form[data-type]');
            Form.ajax.formSubmit($submitBtn);
        }).on('input', 'form[data-type] input, form[data-type] textarea', function() {
            $(this).closest("div").removeClass('form-const__input--error').find('span').text('').hide();
        });
    },

    ajax: {
        url: undefined, obj: {}, send: function(success, failure) {
            $.post(this.url, this.obj, (answer) => {
                this.obj = {};
                answer.result && typeof success === 'function' ? success(answer) :
                    !answer.result && typeof failure === 'function' ? failure(answer) : null;
                Form.ajax.obj = {};
            }, 'JSON');
        },
        formSubmit: function($submitBtn) {
            this.obj.method = 'formSubmit';
            this.obj.formTypeID = parseInt(Form.$form.data('type'));
            this.obj.data = Form.$form.serializeArray();
            this.send(function() {
                $('.thank-form').css("display", "flex")
                $('body').addClass('overflow-hiddenn');
                setTimeout(function(){
                    $('.thank-form').css("display", "none")
                    $('body').removeClass('overflow-hiddenn');
                }, 2500);
                Form.$form.get(0).reset();
                $submitBtn.prop('disabled', false);

                const formType = parseInt(Form.$form.data('type'));

                if (formType == 1) {
                    dataLayer.push({'event': 'Consultation'});
                    console.log('event - Consultation');
                }

                if (formType == 2) {
                    dataLayer.push({'event': 'AnswerToQuestion'})
                    console.log('event - AnswerToQuestion');
                }

                if (formType == 4) {
                    dataLayer.push({'event': 'CallBack'})
                    console.log('event - CallBack');
                }

                if (formType == 3 || formType == 5 || formType == 6) {
                    dataLayer.push({'event': 'ExpertConsultation'})
                    console.log('event - ExpertConsultation');
                }
            }, (answer) => {
                $submitBtn.prop('disabled', false);
                $.each(answer.error, (k, v) => {
                    Form.$form.find(`[name="${k}"]`).closest("div").addClass('form-const__input--error').find('span').text(v).show();
                });
            });
        },
    }
};
