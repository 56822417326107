var $win =  $(window);
let Main = {
    onload: document.addEventListener('DOMContentLoaded', function() {
        Main.init();
    }),
    init: function() {
        this.ajax.url = document.querySelector('meta[name="ajaxUrl"]').content;

        this.bind();
        this.headerFixed();
        this.footerFixed();
        this.mobileMenu();
        //this.mainSlider();
        this.servicesSlider();
        this.equipmentSlider();
        this.enableSelectBoxes();
        this.sliderSpecialist();
        this.sliderReviews();
        this.accordionToggle();
        this.sliderCertificates();
        this.navHover();
        this.animateHomePage();
        this.cursorWatching();
        this.sidebarScrollTop();
        this.caseSelect();
        this.onContextMenu();
        this.serviceSelectMob();
        this.inputMask();
        this.scrollConsult();
        this.scrollToTop();
        this.closedThankForm();
    },
    bind: function() {
        if(!$('.main-screen').hasClass('main-screen')) {
            $(".page").css("background-image", "none")
        }

        $(document).on('click', '.more-cases', function(e) {
            e.preventDefault();
            Main.ajax.getMoreCases();
        }).on('click', '.less-cases', function(e) {
            e.preventDefault();
            Main.ajax.getLessCases();
        }).on('click', '.cases__select.cases-catalog', function(e) {
            e.preventDefault();
            Main.ajax.getCategoryCases($(this).data('id'));
        }).on('click', '.cases__select.index', function(e) {
            e.preventDefault();
            Main.ajax.getCategoryCasesIndex($(this).data('id'));
        });
    },
    headerFixed: function() {
        let footer = $('.footer');
            footer.css('display', "block");
        if ($('body').width() >= 1024){
            $(window).scroll(function() {
                if($(this).scrollTop() < 980) {
                    //footer.css('display', "none");
                    $('main').css("background", "inherit")
                } else {
                    //footer.css('display', 'block');
                    $('main').css("background", "#fff")
                }
                if($('#map').hasClass('contact__map')) {
                    if($(this).scrollTop() < 700) {
                       // footer.css('display', "none");
                        $('main').css("background", "inherit")
                    } else {
                       // footer.css('display', 'block');
                        $('main').css("background", "#fff")
                    }
                }
            });
        } else {
            footer.css('display', "block");
        }
    },
    footerFixed: function() {
        let header = $('.header');

        $(window).scroll(function() {
            if($(this).scrollTop() > 1) {
                header.addClass('header--fixed');
            } else {
                header.removeClass('header--fixed');
            }
        });
    },
    accordionToggle: function () {
        $('.accordion__header').click(function () {
            if ($(this).next('.accordion__content').css('display') === 'block') {
                $(this).next('.accordion__content').slideUp();
                $(this).removeClass('accordion__header--active');
            }
            else {
                $('.accordion__header').removeClass('accordion__header--active');
                $('.accordion__content').slideUp();
                $(this).next('.accordion__content').slideDown();
                $(this).addClass('accordion__header--active');
            }
            let $this = $(this);
            setTimeout(function(){
                if ( $this.hasClass("accordion__header--active") ) {
                    $('html, body').animate({
                        scrollTop: $this.offset().top - 80
                    }, 500);
                }
            }, 600);
        });
    },
    sliderCertificates: () => {
        let swiperC = new Swiper('.swiper-certificates', {
            slidesPerView: 3,
            spaceBetween: 40,
            // centeredSlides: true,
            freeMode: true,
            navigation: {
                bulletClass: 'roundbtn',
                bulletActiveClass: 'active',
                renderBullet: function (index, className) {
                    return '<button class=" ' + className + '"></button>';
                },
                nextEl: '.swiper-button-next-c',
                prevEl: '.swiper-button-prev-c',
            },
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
        });
    },
    sliderSpecialist: () => {
        let swiperS = new Swiper('.swiper-specialist', {
            slidesPerView: 5,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
            },
        });
    },
    sliderReviews: () => {
        let swiperReview = new Swiper('.js-slider-review', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next-2',
                prevEl: '.swiper-button-prev-2',
            },
            breakpoints: {
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1023: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                510: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
            },
        });
    },
    enableSelectBoxes: function (){

        [].slice.call( document.querySelectorAll( 'select.cs-select' ) ).forEach( function(el) {
            new SelectFx(el);
        } );

        $('div.selectBox').each(function(){
            $(this).children('span.selected').html($(this).children('div.selectOptions').children('span.selectOption:first').html());
            $(this).attr('value',$(this).children('div.selectOptions').children('span.selectOption:first').attr('value'));
            $(this).attr('value',$(this).children('div.selectOptions').children('span.selectOption:first').attr('value'));

            $(this).children('span.selected,span.selectArrow').click(function(){
                if($(this).parent().children('div.selectOptions').css('display') == 'none'){
                    $(this).parent().children('div.selectOptions').css('display','flex');
                    $(this).children('span.selected,span.selectArrow').css("transform", "rotate(180deg)")
                }
                else
                {
                    $(this).parent().children('div.selectOptions').css('display','none');
                    $(this).children('span.selected,span.selectArrow').css("transform", "rotate(0deg)")
                }
            });

            $(this).find('span.selectOption').click(function(){
                $(this).parent().css('display','none');
                $(this).closest('div.selectBox').attr('value',$(this).attr('value'));
                $(this).parent().siblings('span.selected').html($(this).html());
                if ($(this).attr('value') == $(this).children('div.selectOptions').children('span.selectOption:first').attr('value')) {
                }

            });
        });
    },
    mobileMenu: function() {

        let touch = $('.icon-menu');
        let menu = $('.header__nav');
        var submenu = $('.menu__item');
        var m = $('.menu');


        touch.on('click', function(e) {
            e.preventDefault();
            menu.toggleClass('active');
            $(this).toggleClass('active');
            setTimeout(() => {
                menu.toggleClass('activeH');
            }, 100)

            $('body').toggleClass('overflow-hiddenn');
            $('#menu__item--service').removeClass("menu__item--submenu");
            $('.indicator').removeClass("indicator--active");
            $('#menu-desc--services').removeClass("menu-desc__item--activeS");
            $('.second-menu').css("display", "none")
        });

        submenu.on('click', function(e) {
                if( $(this).hasClass('menu__item--submenu') ) {
                }
        });

        $( "#menu__item--service").mouseover(() => {
                $('#menu__item--service').addClass("menu__item--submenu");
                $('.indicator').addClass("indicator--active");
                $('#menu-desc--services').addClass("menu-desc__item--activeS");
                $('.second-menu').css("display", "block")

            }
        )
        $( ".second-menu__item" ).hover(
            function() {
            $('.header-info').addClass('header-info--mob-none')
        }, function() {
                $('.header-info').removeClass( "header-info--mob-none" );
            }
        );
    },

    servicesSlider: () => {
        if ($('.slide-services__item').length > 0) {
            $('.services__text').eq(0).addClass('services__text--active')
        let slides = $('.slide-services__item')
        let btns = $('.services__item')
        let text = $('.services__text')
        const btnClick = (n) => {
            for(let t of btns) {
                t.classList.remove('services__item--active')
            }
            btns[n].classList.add('services__item--active')
        }
        const textShow = (n) => {
            for(let x of text) {
                x.classList.remove('services__text--active')
            }
            text[n].classList.add('services__text--active')
        }
        btns.each((index, item) => {
            item.addEventListener('mouseenter', (e) => {
                console.log($(e.target).attr('class'))

                    textShow(index)
                    for (let slide of slides) {
                        slide.classList.remove('prev');
                    }
                    const currentActive = document.querySelector('.slide-services__item--a');
                    if (currentActive) {
                        currentActive.classList.add('prev');
                    }
                   btnClick(index)
                    for (let slide of slides) {
                        slide.classList.remove('slide-services__item--a');
                    }
                    slides[index].classList.add('slide-services__item--a');
            });
        });
    }
    },
    equipmentSlider: function () {
        const prev = $('.slider-equipment-button-prev')
        const next = $('.slider-equipment-button-next')
        const sliders = $('.slider-equipment__item')
        const typesText = $('.equipment__type')
        const text = $('.equipment-desc__text')
        const textMob = $('.equipment-desc__text--mob')
        const dots = $('.slider-equipment__dot')
        let index = 0
        const activeSlide = (n) => {
            for(let i of sliders) {
                i.classList.remove('slider-equipment__item--active')
            }
            sliders[n].classList.add('slider-equipment__item--active')
        }
        const activeType = (n) => {
            for(let i of typesText) {
                i.classList.remove('equipment__type--active')
            }
            typesText[n].classList.add('equipment__type--active')
        }
        const activeText = (n) => {
            for(let i of text) {
                i.classList.remove('equipment-desc__text--active')
            }
            text[n].classList.add('equipment-desc__text--active')
        }
        const activeTextMob = (n) => {
            for(let i of textMob) {
                i.classList.remove('equipment-desc__text--activeMob')
            }
            textMob[n].classList.add('equipment-desc__text--activeMob')
        }
        const activeDot = (n) => {
            for(let i of dots) {
                i.classList.remove('slider-equipment__dot--active')
            }
            dots[n].classList.add('slider-equipment__dot--active')
        }
        const prevSlide = () => {
            if(index === 0){
                index = sliders.length-1
                activeSlide(index)
                activeType(index)
                activeDot(index)
                activeText(index)
                activeTextMob(index)
            } else {
                index--
                activeSlide(index)
                activeType(index)
                activeDot(index)
                activeText(index)
                activeTextMob(index)
            }
        }
        const nextSlide = () => {
            if(index === sliders.length-1) {
                index = 0
                activeSlide(index)
                activeType(index)
                activeDot(index)
                activeText(index)
                activeTextMob(index)
            } else {
                index++
                activeSlide(index)
                activeType(index)
                activeDot(index)
                activeText(index)
                activeTextMob(index)
            }
        }
        dots.each((index, item) => {
            item.addEventListener('click', () => {
                activeDot(index)
                activeSlide(index)
                activeText(index)
                activeTextMob(index)
            })
        })
        next.on('click', nextSlide)
        prev.on('click', prevSlide)
    },
    navHover: function () {
        let items = $('.menu-desc__item')
        let menus = $('.menu__item')

        menus.each((item, index) => {
            index.addEventListener('mouseout', () => {
                for(let b of items) {
                    b.classList.remove('menu-desc__item--active')
                }
                items[item].classList.remove('menu-desc__item--active')
            })
            index.addEventListener('mouseover', () => {
                for(let b of items) {
                    b.classList.remove('menu-desc__item--active')
                }
                items[item].classList.add('menu-desc__item--active')
                if(item !== 1) {
                    $('.second-menu').css("display", "none")
                    document.getElementById('menu__item--service').classList.remove("menu__item--submenu");
                    document.querySelector('.indicator').classList.remove("indicator--active");
                    document.getElementById('menu-desc--services').classList.remove("menu-desc__item--activeS")
                }

            })

        })
    },
    animateHomePage: function () {
        if ($('body').width() >= 1024) {
            $(".main-screen").viewportChecker({
                callbackFunction: function () {
                    $('.main-slider__item--hidden').addClass('main-slider__item--anim')
                    $('.main-screen__desc').addClass('main-screen__desc--anim')
                    setTimeout(() => {
                        $('.main-slider__item').css("opacity", "1")
                        $('.main-slider__item--hidden').removeClass('main-slider__item--anim')
                        // $('.main-slider__item--anim').css("transform", "unset!important")
                    }, 1000)
                }
            })
            $(".about").viewportChecker({
                callbackFunction: function () {
                    $('.about__img').addClass('about__img--animate')
                    $(".about__title").addClass('animate-line')
                }
            })
            $(".left-panel").viewportChecker({
                callbackFunction: function () {
                    $('.left-panel').addClass('left-panel--active')
                }
            })
            $(".advantages").viewportChecker({
                callbackFunction: function () {
                    $('.count').each(function () {
                        $(this).prop('Counter', 0).animate({
                            Counter: $(this).text()
                        }, {
                            duration: 2000,
                            easing: 'swing',
                            step: function (now) {
                                $(this).text(Math.ceil(now));
                            }
                        });
                    });

                    $('.advantages__text p').css("left", "20px")
                }
            })

            $(".services").viewportChecker({
                callbackFunction: function () {
                   $("#slider1").addClass('slide-services__item--show')
                    $(".services__desc").addClass('animate-line')

                    setTimeout(() => {
                        $("#slider1").removeClass('slide-services__item--show')
                        $("#slider1").removeClass('slide-services__item--hidden')
                        // $("#slider1").css("opacity", "1")
                        // $("#slider2").css("opacity", "1")
                        // $("#slider3").css("opacity", "1")
                    }, 1000)
                }
            })
            $(".advantages__quote").viewportChecker({
                callbackFunction: function () {
                    $("#animate-line").addClass('animate-line')
                }
            })
            $(".stages").viewportChecker({
                callbackFunction: function () {
                    $(".stages__title h1").addClass('animate-line-lg')

                    let items = $('.stages__item')
                    items.eq(0).addClass('stages__item--anim')
                    setTimeout(() => items.eq(1).addClass('stages__item--anim'), 100)
                    setTimeout(() => items.eq(2).addClass('stages__item--anim'), 1000)
                    setTimeout(() => items.eq(3).addClass('stages__item--anim'), 2000)
                    setTimeout(() => items.eq(4).addClass('stages__item--anim'), 3000)
                    setTimeout(() => items.eq(5).addClass('stages__item--anim '), 4000)
                    setTimeout(() => items.eq(5).addClass('stages__item--anim-last'), 5000)

                }
            })
            $(".consultation").viewportChecker({
                callbackFunction: function () {
                    $(".consultation__title").addClass('animate-line-consult')
                }
            })
            $(".news").viewportChecker({
                callbackFunction: function () {
                    $(".news__title").addClass('animate-line-news')
                }
            })
            $(".js-anim").each(function() {
                var $el =  $(this);
                $el.viewportChecker({
                    callbackFunction: function () {
                        $el.addClass('animate-el')
                    }
                })
            })
            $(".why-choose").viewportChecker({
                callbackFunction: function () {
                    $(".why-choose__title").addClass('animate-line-why')
                }
            })
            $(".line").viewportChecker({
                callbackFunction: function () {
                    $(".line").addClass('animate-line-case')
                }
            })
        }
        if ($('body').width() <= 1024) {
            $("#slider1").removeClass('slide-services__item--hidden')
        }

    },
    cursorWatching: () => {
        let items = $('.projects__item')
        let itemsImg = $('.projects__item').find($('img'))
        if ($('body').width() >= 1024) {
            if ($('.projects').hasClass('projects')) {
                const item1 = () => {
                    // let boxElem = document.getElementById('projects-case-1');
                    // let pointerElem = document.getElementById('img-case-1');
                    let boxElem = items[0]
                    let pointerElem = itemsImg[0]

                    function onMouseMove(event) {
                        let mouseX = event.clientX;
                        let mouseY = event.clientY;
                        let crd = boxElem.getBoundingClientRect();
                        let activePointer = crd.left <= mouseX && mouseX <= crd.right && crd.top <= mouseY && mouseY <= crd.bottom;

                        if (crd.left <= mouseX && mouseX <= crd.right) {
                            if (pointerElem.classList.contains('box-pointer-hidden')) {
                                pointerElem.classList.remove('box-pointer-hidden');
                            }
                            pointerElem.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
                        } else {
                            pointerElem.classList.add('box-pointer-hidden');
                        }
                    }

                    function disablePointer() {
                        requestAnimationFrame(function hidePointer() {
                            pointerElem.classList.add('box-pointer-hidden');
                        });
                    }

                    boxElem.addEventListener('mousemove', onMouseMove, false);
                    boxElem.addEventListener('mouseleave', disablePointer, false);
                }
                const item2 = () => {
                    // let boxElem = document.getElementById('projects-case-2');
                    // let pointerElem = document.getElementById('img-case-2');

                    let boxElem = items[1]
                    let pointerElem = itemsImg[1]

                    function onMouseMove(event) {
                        let mouseX = event.clientX;
                        let mouseY = event.clientY;
                        let crd = boxElem.getBoundingClientRect();
                        let activePointer = crd.left <= mouseX && mouseX <= crd.right && crd.top <= mouseY && mouseY <= crd.bottom;
                        if (crd.left <= mouseX && mouseX <= crd.right) {
                            if (pointerElem.classList.contains('box-pointer-hidden')) {
                                pointerElem.classList.remove('box-pointer-hidden');
                            }
                            pointerElem.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
                        } else {
                            pointerElem.classList.add('box-pointer-hidden');
                        }
                    }

                    function disablePointer() {
                        requestAnimationFrame(function hidePointer() {
                            pointerElem.classList.add('box-pointer-hidden');
                        });
                    }

                    if (boxElem) {
                        boxElem.addEventListener('mousemove', onMouseMove, false);
                        boxElem.addEventListener('mouseleave', disablePointer, false);
                    }
                }
                const item3 = () => {
                    let boxElem = items[2]
                    let pointerElem = itemsImg[2]

                    function onMouseMove(event) {
                        let mouseX = event.clientX;
                        let mouseY = event.clientY;
                        let crd = boxElem.getBoundingClientRect();
                        let activePointer = crd.left <= mouseX && mouseX <= crd.right && crd.top <= mouseY && mouseY <= crd.bottom;
                        if (crd.left <= mouseX && mouseX <= crd.right) {
                            if (pointerElem.classList.contains('box-pointer-hidden')) {
                                pointerElem.classList.remove('box-pointer-hidden');
                            }
                            pointerElem.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
                        } else {
                            pointerElem.classList.add('box-pointer-hidden');
                        }
                    }

                    function disablePointer() {
                        requestAnimationFrame(function hidePointer() {
                            pointerElem.classList.add('box-pointer-hidden');
                        });
                    }
                    if (boxElem) {
                        boxElem.addEventListener('mousemove', onMouseMove, false);
                        boxElem.addEventListener('mouseleave', disablePointer, false);
                    }
                }
                const item4 = () => {
                    // let boxElem = document.getElementById('projects-case-4');
                    // let pointerElem = document.getElementById('img-case-4');
                    let boxElem = items[3]
                    let pointerElem = itemsImg[3]

                    function onMouseMove(event) {
                        let mouseX = event.clientX;
                        let mouseY = event.clientY;
                        let crd = boxElem.getBoundingClientRect();
                        let activePointer = crd.left <= mouseX && mouseX <= crd.right && crd.top <= mouseY && mouseY <= crd.bottom;
                        if (crd.left <= mouseX && mouseX <= crd.right) {
                            if (pointerElem.classList.contains('box-pointer-hidden')) {
                                pointerElem.classList.remove('box-pointer-hidden');
                            }
                            pointerElem.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
                        } else {
                            pointerElem.classList.add('box-pointer-hidden');
                        }
                    }

                    function disablePointer() {
                        requestAnimationFrame(function hidePointer() {
                            pointerElem.classList.add('box-pointer-hidden');
                        });
                    }

                    if (boxElem) {
                        boxElem.addEventListener('mousemove', onMouseMove, false);
                        boxElem.addEventListener('mouseleave', disablePointer, false);
                    }
                }
                const item5 = () => {
                    let boxElem = items[4]
                    let pointerElem = itemsImg[4]

                    function onMouseMove(event) {
                        let mouseX = event.clientX;
                        let mouseY = event.clientY;
                        let crd = boxElem.getBoundingClientRect();
                        let activePointer = crd.left <= mouseX && mouseX <= crd.right && crd.top <= mouseY && mouseY <= crd.bottom;
                        if (crd.left <= mouseX && mouseX <= crd.right) {
                            if (pointerElem.classList.contains('box-pointer-hidden')) {
                                pointerElem.classList.remove('box-pointer-hidden');
                            }
                            pointerElem.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
                        } else {
                            pointerElem.classList.add('box-pointer-hidden');
                        }
                    }

                    function disablePointer() {
                        requestAnimationFrame(function hidePointer() {
                            pointerElem.classList.add('box-pointer-hidden');
                        });
                    }

                    if (boxElem) {
                        boxElem.addEventListener('mousemove', onMouseMove, false);
                        boxElem.addEventListener('mouseleave', disablePointer, false);
                    }
                }
                const item6 = () => {
                    let boxElem = items[5]
                    let pointerElem = itemsImg[5]

                    function onMouseMove(event) {
                        let mouseX = event.clientX;
                        let mouseY = event.clientY;
                        let crd = boxElem.getBoundingClientRect();
                        let activePointer = crd.left <= mouseX && mouseX <= crd.right && crd.top <= mouseY && mouseY <= crd.bottom;
                        if (crd.left <= mouseX && mouseX <= crd.right) {
                            if (pointerElem.classList.contains('box-pointer-hidden')) {
                                pointerElem.classList.remove('box-pointer-hidden');
                            }
                            pointerElem.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
                        } else {
                            pointerElem.classList.add('box-pointer-hidden');
                        }
                    }

                    function disablePointer() {
                        requestAnimationFrame(function hidePointer() {
                            pointerElem.classList.add('box-pointer-hidden');
                        });
                    }

                    if (boxElem) {
                        boxElem.addEventListener('mousemove', onMouseMove, false);
                        boxElem.addEventListener('mouseleave', disablePointer, false);
                    }
                }

                // item1();
                // item2();
                // item3();
                // item4();
                // item5();
                // item6();
            }
        }
    },
    caseSelect: () => {

        let items = $('.cases__select')

        items.each((index, item) => {
            item.addEventListener('click', () => {
                $('.cases__info').text(item.textContent)
                for (let i of items) {
                    i.classList.remove('cases__select--active')
                }
                items[index].classList.add('cases__select--active')
            })
        })
    },
    onContextMenu: () => {
        $('img').bind('contextmenu', function(e) {
            return false;
        });
    },
    serviceSelectMob: () => {
        if ($('body').width() <= 1024) {
            $('.service__select').click(() => {
                $('.service__items').slideToggle("show")
                $('.service__info').toggleClass("service__info--active")
                $('body').toggleClass('overflow-hiddenn');
                let $this = $(this);
                setTimeout(function(){
                    if ( $(".service__info").hasClass("service__info--active") ) {
                        $('html, body').animate({
                            scrollTop: $(".service__sidebar").offset().top - 0
                        }, 500);
                    }
                }, 600);
                // $('.service__select').toggle(() => {
                    $('.service__sidebar--fixed').toggleClass("service__sidebar--mdHeight")
                // })

            })
            let items = $('.service__item')

            items.each((index, item) => {
                item.addEventListener('click', () => {
                    $('.service__info').text(item.textContent)
                    for (let i of items) {
                        i.classList.remove('service__item--active')
                    }
                    items[index].classList.add('service__item--active')
                    $('.service__items').slideToggle("hide")
                    $('.service__info').removeClass("service__info--active")
                    $('.service__sidebar--fixed').removeClass("service__sidebar--mdHeight")
                    $('body').removeClass('overflow-hiddenn');
                })
            })
        }
    },
    inputMask: () => {
        $('input[name="phone"]').inputmask({alias: 'phone', showMaskOnHover: true, mask:"+380(##)###-##-##"}).on('keydown', function (e) {
            if ($(this).inputmask('isComplete')) {
                e.preventDefault();
                return false;
            }
        });
    },
    scrollConsult: () => {
        if ($('body').width() <= 480) {
            $(".main-screen__btn").click(function() { // ID откуда кливаем
                $('html, body').animate({
                    scrollTop: $(".consultation").offset().top -40  // класс объекта к которому приезжаем
                }, 2000); // Скорость прокрутки
            });
        } else {
            $(".main-screen__btn").click(function () { // ID откуда кливаем
                $('html, body').animate({
                    scrollTop: $(".consultation").offset().top - 200  // класс объекта к которому приезжаем
                }, 2000); // Скорость прокрутки
            });
        }
    },
    sidebarScrollTop: () => {
            console.log()
        if ($('.stop-sidebar').hasClass('stop-sidebar')) {
            var $window = $(window);
            var $sidebar = $(".js-service__sidebar");
            var $sidebarTop = $sidebar.position().top;
            var $sidebarHeight = $sidebar.height();
            var $footer = $('.stop-sidebar');
            var $footerTop = $footer.position().top - 100;

            var target = $('.js-service__content');
            var targetPos = target.offset().top;
            var winHeight = $(window).height();
            var scrollToElem = targetPos - winHeight;
           // if($('.check').hasClass('check')){
                $window.scroll(function (event) {
                    var winScrollTop = $(this).scrollTop();
                    if (winScrollTop > scrollToElem) {
                        //сработает когда пользователь доскроллит к элементу с классом .elem

                    }
                    if ($('body').width() <= 1920) {
                        if (pageYOffset > 1010) {
                           // if($('.check').hasClass('check')){
                                $sidebar.addClass("service__sidebar--fixed");
                                $('.js-service__content').addClass("service__content--fixed");
                            //}
                            var $scrollTop = $window.scrollTop();
                        } else {
                            $sidebar.removeClass("service__sidebar--fixed");
                            $('.js-service__content').removeClass("service__content--fixed");
                        }
                    }
                    if ($('body').width() < 1560) {
                        if (pageYOffset > 780) {
                           // if($('.check').hasClass('check')){
                                $sidebar.addClass("service__sidebar--fixed");
                                $('.js-service__content').addClass("service__content--fixed");
                           // }
                            var $scrollTop = $window.scrollTop();
                        } else {
                            $sidebar.removeClass("service__sidebar--fixed");
                            $('.js-service__content').removeClass("service__content--fixed");
                        }
                    }
                    if ($('body').width() < 1024) {
                        if (pageYOffset > 560) {
                            $sidebar.addClass("service__sidebar--fixed");
                            var $scrollTop = $window.scrollTop();
                            $('.js-service__content').addClass('service__content--mdFixed')
                        } else {
                            $sidebar.removeClass("service__sidebar--fixed");
                            $('.js-service__content').removeClass('service__content--mdFixed')
                        }
                    }
                    var $topPosition = Math.max(0, $sidebarTop - $scrollTop);

                    if ($scrollTop + $sidebarHeight > $footerTop) {
                        var $topPosition = Math.min($topPosition, $footerTop - $scrollTop - $sidebarHeight);
                    }

                    $sidebar.css("top", $topPosition);
                });
            //}

        }
    },
    cursorWatchingAccordion: () => {
        const itemAccordion = () => {
            let boxElem = document.getElementById('projects-case-accor');
            let pointerElem = document.getElementById('img-case-accor');
            function onMouseMove(event) {
                let mouseX = event.clientX;
                let mouseY = event.clientY;
                let crd = boxElem.getBoundingClientRect();
                let activePointer = crd.left <= mouseX && mouseX <= crd.right && crd.top <= mouseY && mouseY <= crd.bottom;

                if (crd.left <= mouseX && mouseX <= crd.right) {
                    if (pointerElem.classList.contains('box-pointer-hidden-accor')) {
                        pointerElem.classList.remove('box-pointer-hidden-accor');
                    }
                    pointerElem.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
                } else {
                    pointerElem.classList.add('box-pointer-hidden-accor');
                }
            }

            function disablePointer() {
                requestAnimationFrame(function hidePointer() {
                    pointerElem.classList.add('box-pointer-hidden-accor');
                });
            }

            boxElem.addEventListener('mousemove', onMouseMove, false);
            boxElem.addEventListener('mouseleave', disablePointer, false);
        }
        itemAccordion()
    },
    scrollToTop: () => {
        $('.btn-to-top').on('click', function() {
            $('html, body').animate({
                scrollTop: $('html, body').offset().top,
            });
        });
    },
    closedThankForm: () => {
        $('.thank-form__btn').click(() => {
            $('.thank-form').css("display", "none")
            $('body').removeClass('overflow-hiddenn');
        })
    },

    ajax: {
        url: undefined, obj: {}, send: function(success, failure) {
            $.post(this.url, this.obj, (answer) => {
                this.obj = {};
                answer.result && typeof success === 'function' ? success(answer) :
                    !answer.result && typeof failure === 'function' ? failure(answer) : null;
                Form.ajax.obj = {};
            }, 'JSON');
        },
        formSubmit: function($submitBtn) {
            this.obj.method = 'formSubmit';
            this.obj.formTypeID = parseInt(Form.$form.data('type'));
            this.obj.data = Form.$form.serializeArray();
            this.send(function() {
                $('thank-form').css("display", "flex")
                $('body').addClass('overflow-hiddenn');
                setTimeout(function(){
                    $('thank-form').css("display", "none")
                    $('body').removeClass('overflow-hiddenn');
                }, 5000);
                Form.$form.get(0).reset();
                $submitBtn.prop('disabled', false);
            }, (answer) => {
                $submitBtn.prop('disabled', false);
                $.each(answer.error, (k, v) => {
                    Form.$form.find(`[name="${k}"]`).closest("div").addClass('form-const__input--error').find('span').text(v).show();
                });
            });
        },
        getMoreCases: function () {
            this.obj.method = 'getMoreCases';
            this.send(function(answer) {
                $('div.projects__item').last().after(answer.html);
                if (answer.buttonFlag == false) {
                    $('.more-cases').hide();
                    $('.less-cases').show();
                }
            });
        },
        getLessCases: function () {
            this.obj.method = 'getLessCases';
            this.send(function(answer) {
                $('div.cases__content').html(answer.html);
                $('.more-cases').show();
                $('.less-cases').hide();
            })
        },
        getCategoryCases: function (id) {
            this.obj.method = 'getCategoryCases';
            this.obj.id = id;
            this.send(function(answer) {
                $('div.cases__content').html(answer.html);
                console.log(answer.isButtonShow)
                if (answer.isButtonShow) {
                    $('.pagination-2').show();
                } else {
                    $('.pagination-2').hide();
                }
            })
        },
        getCategoryCasesIndex: function (id) {
            this.obj.method = 'getCategoryCasesIndex';
            this.obj.id = id;
            this.send(function(answer) {
                $('div.cases__content').html(answer.html);
            })
        }
    }
}

$(document).ready(function() {
    $('.js-more-item').each(function() {
            var $el = $(this),
                $parent = $el.closest('.js-wrap-items'),
                $wrap = $parent.find('.js-content-wrap'),
                $content = $parent.find('.js-content');
            var $block1 = $parent.find('.reviews__title');
            var lineHeight1 = parseFloat($block1.css('line-height'));
            var blockHeight1 = $block1.height();
            var lineCount1 = Math.floor(blockHeight1 / lineHeight1);
            var $block2 = $parent.find('.reviews__description');
            var lineHeight2 = parseFloat($block2.css('line-height'));
            var blockHeight2 = $block2.height();
            var lineCount2 = Math.floor(blockHeight2 / lineHeight2);
            if ($block1.length > 0 && lineCount1 > 3 || $block2.length > 0 && lineCount2 > 3) {
                $parent.removeClass('full').addClass('over');
            } else {
               $parent.addClass('full').removeClass('over');
            }  
    })
    $win.on('resize', function() {
        $('.js-more-item').each(function() {
            var $el = $(this),
                $parent = $el.closest('.js-wrap-items'),
                $wrap = $parent.find('.js-content-wrap'),
                $content = $parent.find('.js-content');
            $parent.removeClass('over full');
            var $block1 = $parent.find('.reviews__title');
            var lineHeight1 = parseFloat($block1.css('line-height'));
            var blockHeight1 = $block1.height();
            var lineCount1 = Math.floor(blockHeight1 / lineHeight1);
            var $block2 = $parent.find('.reviews__description');
            var lineHeight2 = parseFloat($block2.css('line-height'));
            var blockHeight2 = $block2.height();
            var lineCount2 = Math.floor(blockHeight2 / lineHeight2);
            if ($block1.length > 0 && lineCount1 > 3 || $block2.length > 0 && lineCount2 > 3) {
                $parent.removeClass('full').addClass('over');
            } else {
               $parent.addClass('full').removeClass('over');
            }  
        })
    });

    $('.js-more-item').on('click', function() {
        var $el = $(this);
            $('#reviewPopup').addClass('open');
            $('.js-review-content').html($el.closest('.reviews__item').html())
    });
    $('.js-close-popup').on('click', function() {
        var $el = $(this);
            $('#reviewPopup').removeClass('open');
    });

        /* accordion packets */
    $('.js-accordion-head').on('click', function() {
        var $el = $(this);
        $el.closest('.js-accordion-wrap').toggleClass('active').find('.js-accordion-body').slideToggle(200);
        if ($el.closest('.js-accordion-wrap').is('.active')) {
            $el.closest('.js-accordion-wrap').siblings('.js-accordion-wrap').removeClass('active').find('.js-accordion-body').slideUp(200);
            //if ($el.closest('.js-accordion-wrap').find('select:not(.styled)').length) {
            //$el.closest('.js-accordion-wrap').find('select:not(.styled)').addClass('styled').styler();
            //}
        }
    });
    /* accordion packets */

    $('.js-anchor').on('click', function(){
        var $href = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $($href).offset().top - $('.header').outerHeight()
        }, 500);
        return false;
    });
    $('.js-close-menu').on('click', function(){
        $(this).closest('.menu-has-children').removeClass('open').find('.open-submenu').removeClass('open-submenu')
        return false;
    })
    $('.js-close-submenu').on('click', function(){
        $(this).closest('.open-submenu').removeClass('open-submenu')
        return false;
    })
    $('.js-menu-link').on('click', function(){
        $(this).parent('.menu-has-children').addClass('open')
        return false;
    })
    $('.js-submenu-link').on('click', function(){
        $(this).parent('li').addClass('open-submenu')
        return false;
    })

    $('.js-more-link').on('click', function() {
        var $el = $(this),
            $parent = $el.closest('.js-more-links'),
                    $btn =$(this).find('span'),
                btnText = $btn.text();

            if ($parent.is('.full')) {
              $parent.removeClass('full');
              $btn.text($el.attr('data-more'));
            } else {
              $parent.addClass('full');
              $btn.text($el.attr('data-less'));
            }
    });
    $('.js-more-link').each(function() {
            var $el = $(this),
                $wrap = $el.closest('.js-more-links').find('.js-content-wrap'),
                $content = $el.closest('.js-more-links').find('.js-content');
            if ($content.find('li').length <= 8) {
                $el.hide();

            } else {
                $el.css('display', '');
            }  
    })
    $win.on('resize', function() {
        $('.js-more-link').each(function() {
            var $el = $(this),
                $wrap = $el.closest('.js-more-link').find('.js-content-wrap'),
                $content = $el.closest('.js-wrap-items').find('.js-content');
            if ($content.find('li').length <= 8) {
                $el.hide();
            } else {
                $el.css('display', '');
            }  
        })
    })

});
